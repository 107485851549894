import handleCompletedScheduleExistCheck from './handleCompletedScheduleExistCheck';

/**
 * @description
 * Function that judge whether the add row button is visible or not
 * @param {string} scheType
 * Schedule type LIVE or DROP
 * @param {object} scheduleData
 * Schedule data from each row
 * @returns {boolean}
 * Whether the add row button is visible or not
 */
const judgeAddRowButtonVisibility = ({
  pageCategory,
  scheType,
  scheduleData,
  jobSet,
  locationSet,
  jobSetIdx,
  locationSetIdx,
  scheduleType,
  locationInfo,
}) => {
  const { type, ordering } = scheduleData;

  if (jobSet?.[jobSetIdx]?.dual_job) {
    const {
      from_location: fr,
      live_dilv_location: ld,
      to_location: to,
    } = jobSet?.[jobSetIdx];

    if (fr && fr?.type === 'RTN' && fr?.ordering === 0) return false;
    if (ld && ld?.type === 'RTN' && ld?.ordering === 0) return false;
    if (to && to?.type === 'RTN' && to?.ordering === 0) return false;
  }

  if (
    !handleCompletedScheduleExistCheck({
      pageCategory,
      scheduleType,
      jobSet,
      locationSet,
      jobSetIdx,
      locationSetIdx,
      locationInfo,
      scheduleData,
    })
  ) {
    return false;
  }

  if (
    pageCategory === 'van' &&
    type === 'DILV' &&
    jobSet?.[jobSetIdx]?.to_location &&
    jobSet?.[jobSetIdx]?.to_location?.type === 'RTN'
  )
    return false;

  if (type === 'RTN')
    /** If type is RTN(Return), schedule add button would not be rendered */
    return false;

  /** If schedule type is DROP */
  if (scheType === 'DROP') {
    /** If row type is DILV and ordering is 0, schedule add button would not be rendered */
    if (type === 'DILV' && ordering === 0) return false;
  }

  /** If row has odd ordering, add button would not be rendered  */
  if (ordering % 2 === 1) return false;

  return true;
};

/**
 * @description
 * Function that judge whether the delete row button is visible or not
 * @param {object} scheduleData
 * Schedule data from each row
 * @returns {boolean}
 * Whether the delete row button is visible or not
 */
const judgeDeleteRowButtonVisibility = ({
  scheType,
  scheduleData,
  jobSet,
  jobSetIdx,
  locationInfo,
  locationSet,
  locationSetIdx,
}) => {
  /** import / export / van / bare-chassis */
  const pageCategory =
    document?.URL?.split('/')?.[document?.URL?.split('/')?.length - 2];

  const {
    type,
    ordering,
    actual_in_date: acctInDate,
    actual_in_time: acctInTime,
    actual_out_date: acctOutDate,
    actual_out_time: acctOutTime,
    status,
  } = scheduleData;

  if (jobSet?.[jobSetIdx]?.dual_job) return false;

  /**
   * Below is the condition that the row cannot be deleted
   * 1. Skip when Van wo, exist location set more than 1 (from 2)
   * 2. Only process in Drop and Live schedule. Not LVAN
   * 3. Only process when location set is less than 2
   */
  if (
    pageCategory !== 'van' &&
    ['DROP', 'LIVE'].includes(scheType) &&
    locationSet?.length <= 2
  ) {
    // When location set exist
    if (
      locationSet?.length <= 2 &&
      locationSet?.[0]?.ordering > 0 &&
      locationSetIdx === 0
    ) {
      if (pageCategory === 'export') {
        const firstJobSet = jobSet?.[0];
        if (firstJobSet) {
          let isActualExist = false;

          // Check actual-in or actual-out exist
          const fr = firstJobSet?.from_location;
          const ld = firstJobSet?.live_dilv_location;
          const to = firstJobSet?.to_location;
          [fr, ld, to].forEach(location => {
            if (
              location &&
              (location?.actual_in_date ||
                location?.actual_in_time ||
                location?.actual_out_date ||
                location?.actual_out_time)
            ) {
              isActualExist = true;
            }
          });

          // If actual-in or actual-out exist, hide delete button
          if (isActualExist) return false;
        }
      } else {
        const lastJobSet = jobSet?.[jobSet?.length - 1];
        if (lastJobSet) {
          let isActualExist = false;

          // Check actual-in or actual-out exist
          const fr = lastJobSet?.from_location;
          const ld = lastJobSet?.live_dilv_location;
          const to = lastJobSet?.to_location;
          [fr, ld, to].forEach(location => {
            if (
              location &&
              (location?.actual_in_date ||
                location?.actual_in_time ||
                location?.actual_out_date ||
                location?.actual_out_time)
            ) {
              isActualExist = true;
            }
          });

          // If actual-in or actual-out exist, hide delete button
          if (isActualExist) return false;
        }
      }
    }
    // When location set notexist
    else if (locationSet?.length === 0) {
      if (
        pageCategory === 'export' &&
        jobSetIdx === 0 &&
        type === 'P/O' &&
        ordering === 0
      ) {
        const firstJobSet = jobSet?.[0];
        if (firstJobSet) {
          let isActualExist = false;

          // Check actual-in or actual-out exist
          const fr = firstJobSet?.from_location;
          const ld = firstJobSet?.live_dilv_location;
          const to = firstJobSet?.to_location;

          if (fr?.type === 'P/O' && fr?.ordering === 0) {
            [fr, ld, to].forEach(location => {
              if (
                location &&
                (location?.actual_in_date ||
                  location?.actual_in_time ||
                  location?.actual_out_date ||
                  location?.actual_out_time)
              ) {
                isActualExist = true;
              }
            });

            // If actual-in or actual-out exist, hide delete button
            if (isActualExist) return false;
          }
        }
      } else if (
        pageCategory !== 'export' &&
        jobSetIdx === jobSet?.length - 1 &&
        type === 'RTN' &&
        ordering === 0
      ) {
        const lastJobSet = jobSet?.[jobSet?.length - 1];
        if (lastJobSet) {
          let isActualExist = false;

          // Check actual-in or actual-out exist
          const fr = lastJobSet?.from_location;
          const ld = lastJobSet?.live_dilv_location;
          const to = lastJobSet?.to_location;

          if (to?.type === 'RTN' && to?.ordering === 0) {
            [fr, ld, to].forEach(location => {
              if (
                location &&
                (location?.actual_in_date ||
                  location?.actual_in_time ||
                  location?.actual_out_date ||
                  location?.actual_out_time)
              ) {
                isActualExist = true;
              }
            });

            // If actual-in or actual-out exist, hide delete button
            if (isActualExist) return false;
          }
        }
      }
    }
  }

  /** Set invisible delete row button when status is complete */
  if (status !== 'Pending') return false;

  /** If actual-in or actual-out is updated, that row cannot be deleted */
  if (acctInDate || acctInTime || acctOutDate || acctOutTime) return false;

  /** All pages require DILV, so the requirement row cannot be deleted */
  if (ordering === 0 && type === 'DILV') {
    return false;
  }

  /**
   * Export page requires RTN and the others require P/O
   * Requirement rows cannot be deleted
   */
  if (ordering === 0) {
    if (pageCategory === 'export' && type === 'RTN') return false;

    if (pageCategory !== 'export' && type === 'P/O') return false;
  }

  /** If schedule type is DROP */
  if (scheType === 'DROP') {
    /** P/U that has ordering 0 cannot be deleted */
    if (ordering === 0 && type === 'P/U') return false;
  }

  /** If row has odd ordering, add button would not be rendered  */
  if (ordering !== 0 && ordering % 2 === 1) return false;

  return true;
};

/**
 * @description
 * Judge whether the button is visible or not
 * @param {object} scheduleData
 * Schedule data from each row
 * @param {string} buttonType
 * Button type
 * @returns {boolean}
 * Whether the button is visible or not
 */
const handleScheduleRowButtonVisibility = ({
  pageCategory,
  scheType,
  scheduleData,
  jobSet,
  locationSet,
  jobSetIdx,
  locationSetIdx,
  scheduleType,
  locationInfo,
  buttonType,
}) => {
  if (buttonType === 'ADD_ROW') {
    return judgeAddRowButtonVisibility({
      pageCategory,
      scheType,
      scheduleData,
      jobSet,
      locationSet,
      scheduleType,
      jobSetIdx,
      locationSetIdx,
      locationInfo,
    });
  }
  if (buttonType === 'DELETE_ROW') {
    return judgeDeleteRowButtonVisibility({
      scheType,
      scheduleData,
      jobSet,
      jobSetIdx,
      locationInfo,
      locationSet,
      locationSetIdx,
    });
  }
  return true;
};

export default handleScheduleRowButtonVisibility;
