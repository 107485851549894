import React from 'react';
import { FormProvider } from 'react-hook-form';
import UpdateInfoArea from '../../Common/UpdateInfoArea';
import BookingDetailContent from '../../Common/BookingDetailContent';
import getValidBookingData from '../../../../../utils/Dispatch/Export/Booking/getValidBookingData';
import {
  DISPATCH_BOOKING_STATUS_INACTIVE,
  DISPATCH_BOOKING_STATUS_ACTIVE,
} from '../../../../../constants/Dispatch/Export/Common/bookingModalStatus';
import updateButtonDisabled from '../../../../../utils/Common/Handler/updateButtonDisabled';

const UpdateBookingDetailContent = ({
  editData,
  bookingSeq,
  queryObject,
  bkDetailMutator,
  setTabIndexOpen,
  updateBookingController,
  saveButtonRef,
  ...props
}) => {
  return (
    <FormProvider {...updateBookingController}>
      <form
        id="bookingDetailForm"
        onSubmit={updateBookingController.handleSubmit(data => {
          updateButtonDisabled({ ref: saveButtonRef, disabled: true });
          bkDetailMutator({
            bookingSeq,
            bookingForm: getValidBookingData(data),
            buttonRef: saveButtonRef,
          });
          setTabIndexOpen(0);
        })}
        className="flex flex-col gap-y-[27px] h-fit"
      >
        <UpdateInfoArea data={editData} />
        <BookingDetailContent
          status={
            queryObject?.status === 'inactive'
              ? DISPATCH_BOOKING_STATUS_INACTIVE
              : DISPATCH_BOOKING_STATUS_ACTIVE
          }
          isEditMode
          editData={editData}
          {...props}
        />
      </form>
    </FormProvider>
  );
};

export default UpdateBookingDetailContent;
