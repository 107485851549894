import React, { useEffect, useState } from 'react';
import StyledDispatchDataGrid from '../../../../../styles/Dispatch/Common/StyledDispatchDataGrid';
import CommonDataGrid from '../../../../CommonDataGrid';
import { columnTypes } from '../../../../../constants/Dispatch/Common/dataGridOptions';
import setResultColDef from '../../../../../utils/Dispatch/Common/Generator/setResultColDef';
import getModeType from '../../../../../utils/Dispatch/Common/Generator/getModType';
import useControlTotalInfo from '../../../../../hooks/Common/TotalBottomRow/useControlTotalInfo';
import TotalBottomBar from '../../../../Common/CommonTotalBottomBar';

const VanDelivery = ({
  gridRef,
  rowData,
  isEditable,
  status,
  clickCell,
  clickSave,
  handleRowEditingStart,
  handleRowEditingStop,
  editParam,
  subFilter,
  ...props
}) => {
  const [columnDefs, setColumnDefs] = useState(
    setResultColDef({
      isEditable,
      status,
      rowData,
      editParam,
      subFilter,
      ...props,
    }),
  );

  useEffect(() => {
    setColumnDefs(
      setResultColDef({
        isEditable,
        status,
        rowData,
        editParam,
        subFilter,
        ...props,
      }),
    );
  }, [rowData, editParam?.pool, editParam?.booking]);

  const { totalRows, setChangeFlag } = useControlTotalInfo({
    gridRef,
    queryData: rowData?.result,
  });

  return (
    <StyledDispatchDataGrid>
      <CommonDataGrid
        gridRef={gridRef}
        data={rowData?.result}
        modType={getModeType({ columnDefs, isEditable })}
        columnDefs={columnDefs}
        columnTypes={columnTypes}
        onCellClicked={clickCell}
        onRowValueChanged={clickSave}
        undoRedoCellEditing
        undoRedoCellEditingLimit={1}
        onRowEditingStart={handleRowEditingStart}
        onRowEditingStop={handleRowEditingStop}
        onFilterChanged={() => {
          setChangeFlag(prev => !prev);
        }}
        onSelectionChanged={() => {
          setChangeFlag(prev => !prev);
        }}
        onGridReady={() => {
          setChangeFlag(prev => !prev);
        }}
      />
      <TotalBottomBar totalRows={totalRows} />
    </StyledDispatchDataGrid>
  );
};

export default VanDelivery;
