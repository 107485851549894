import { useQuery } from '@tanstack/react-query';
import {
  DISPATCH_PAGE_EXPORT_BOOKING,
  DISPATCH_TYPE_BOOKING,
  DISPATCH_TYPE_EXPORT,
} from '../../../../constants/Dispatch/Common/category';
import { DISPATCH_STATUS_VALUE } from '../../../../constants/Dispatch/Common/statusValue';
import getButtonCount from '../../../../services/Dispatch2/Common/Get/getButtonCount';
import generateInitAdvancedForm from '../../../../utils/Dispatch/Common/Generator/generateInitAdvancedForm';
import getQueries from '../../../../utils/Dispatch/Common/Generator/getQueries';
import getYardQueries from '../../../../utils/Dispatch/Common/Generator/getYardQueries';
import dispatchKeys from '../../keys';

const useGetButtonCount = ({
  division,
  category,
  queryObject,
  yardQueryObject,
  status,
  filterStatus,
}) => {
  const yardParamQueries = getYardQueries({
    yardQueryObject,
    status: filterStatus,
    isCounter: true,
  });

  const paramQueries = getQueries({
    queryObject:
      status === DISPATCH_PAGE_EXPORT_BOOKING
        ? generateInitAdvancedForm({
            category,
            woStatus: filterStatus,
          })
        : queryObject,
  });
  const paramType =
    category === DISPATCH_TYPE_BOOKING ? DISPATCH_TYPE_EXPORT : category;

  if (
    status !== DISPATCH_PAGE_EXPORT_BOOKING &&
    paramQueries?.status?.includes('active')
  ) {
    delete paramQueries?.status;
  }

  const result = useQuery({
    queryKey: dispatchKeys.countDivCategoryStatusQuery({
      division,
      category: paramType,
      paramQueries,
      yardParamQueries,
    }),
    queryFn: () =>
      getButtonCount({
        paramQueries,
        yardParamQueries,
        category: paramType,
      }),
    refetchOnWindowFocus: false,
    enabled:
      !!category &&
      !!division &&
      !!filterStatus &&
      !!DISPATCH_STATUS_VALUE?.[filterStatus] &&
      filterStatus !== DISPATCH_PAGE_EXPORT_BOOKING &&
      !paramQueries?.status,
  });
  return result;
};

export default useGetButtonCount;
