import handleQueryError from '../../../../utils/Common/Handler/handleQueryError';
import getURLSearchParamsObject from '../../../../utils/Dispatch/Common/Generator/getURLSearchParamsObject';
import dispatchDefaultAPI from '../../api';

const getDispatchList = ({
  category,
  status,
  paramQueries,
  yardParamQueries,
  subFilter,
}) => {
  const params = getURLSearchParamsObject({
    status,
    ...(subFilter?.includes('lfd') ? { lfd: subFilter === 'lfd' } : {}),
    ...(subFilter?.includes('schedule')
      ? { sched: subFilter === 'schedule' }
      : {}),
    ...(subFilter?.includes('booking') ? { booking: true } : {}),
    ...(subFilter?.includes('no-booking') ? { booking: false } : {}),
    ...paramQueries,
    ...yardParamQueries,
  });
  try {
    const response = dispatchDefaultAPI.get(`${category}`, {
      params,
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getDispatchList;
