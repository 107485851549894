import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { REGEX_NUMBER_ONLY_POSITIVE_DECIMAL } from '../../../../../constants/Common/regex';
import { COMMON_FREEDAYS_SELECT_OPTION } from '../../../../../constants/Common/selectOption';
import {
  DISPATCH_BOOKING_STATUS_ADD,
  DISPATCH_BOOKING_STATUS_INACTIVE,
} from '../../../../../constants/Dispatch/Export/Common/bookingModalStatus';
import CommonButton from '../../../../CommonButton/CommonButton';
import CommonAutoComplete from '../../../../Inputs/CommonAutoComplete/CommonAutoComplete';
import CommonDate from '../../../../Inputs/CommonDate/CommonDate';
import CommonFile from '../../../../Inputs/CommonFile/CommonFile';
import CommonSelect from '../../../../Inputs/CommonSelect/CommonSelect';
import CommonTextInput from '../../../../Inputs/CommonTextInput/CommonTextInput';
import CommonTextSelect from '../../../../Inputs/CommonTextSelect/CommonTextSelect';
import CommonTime from '../../../../Inputs/CommonTime/CommonTime';
import DispatchBookingTable from '../DispatchBookingTable';
import HistoryLogSection from './HistoryLogSection';

const BookingDetailContent = ({
  status,
  size,
  type,
  ssl,
  billTo,
  loc,
  isEditMode,
  editData,
  isLoadingBillToLoc,
}) => {
  const IS_DISABLED_COUNT = true;

  const { setValue, getValues } = useFormContext();

  const [isDisabled, setIsDisabled] = useState(true);
  const [isDisabledBookingNo, setIsDisabledBookingNo] = useState(true);

  const [logERDOpen, setLogERDOpen] = useState(false);
  const [logCOOpen, setLogCOOpen] = useState({ date: false, time: false });

  useEffect(() => {
    const tempIsDisabled = status === DISPATCH_BOOKING_STATUS_INACTIVE;

    setIsDisabled(tempIsDisabled);
    setIsDisabledBookingNo(
      status !== DISPATCH_BOOKING_STATUS_ADD || tempIsDisabled,
    );
  }, [status]);

  const [docURL, setDocURL] = useState('');
  const watchDocument = useWatch({ name: 'doc' });

  const [erdDefaultValue, setERDDefaultValue] = useState(undefined);
  const [coDefaultValues, setCODefaultValue] = useState([undefined, undefined]);

  useEffect(() => {
    setDocURL(editData?.doc || '');
    setERDDefaultValue(editData?.erd || undefined);
    setCODefaultValue(editData?.cutoff?.split(' ') || [undefined, undefined]);
  }, [editData]);

  useEffect(() => {
    if (!!watchDocument && typeof watchDocument === 'object') {
      setDocURL(URL.createObjectURL(watchDocument));
    }
    if (watchDocument === null) {
      setDocURL('');
    }
  }, [watchDocument]);

  return (
    <div className="pb-[8px]">
      <div className="grid grid-cols-6 w-[910px] gap-x-[20px] gap-y-[10px]">
        <div className="col-span-2">
          <CommonTextInput
            label="BOOKING #"
            inputName="bk_no"
            required
            disabled={isDisabledBookingNo}
            onChangeHandler={({ e, onChange }) => {
              onChange?.(e?.target?.value);
            }}
          />
        </div>
        <div className="col-span-2">
          <CommonAutoComplete
            label="BILL TO"
            inputName="billto"
            required
            maxLength={8}
            disabled={isDisabled}
            option={billTo || []}
            onChangeHandler={({ e, selectProps, onChange }) => {
              onChange?.(selectProps?.value);
            }}
            isLoading={isLoadingBillToLoc}
          />
        </div>
        <div className="col-span-2">
          <div className="flex flex-row gap-x-[20px]">
            <div>
              <CommonFile
                label="DOCUMENT"
                inputName="doc"
                disabled={isDisabled}
              />
            </div>
            <div className="items-start mt-[14px]">
              <CommonButton
                category="pdf"
                height="30px"
                width="70px"
                onClick={() => {
                  window.open(docURL, '_blank');
                }}
                disabled={!docURL}
              >
                PDF
              </CommonButton>
            </div>
          </div>
        </div>
        <div className="col-span-2">
          <CommonSelect
            label="SSL"
            inputName="ssl"
            required
            disabled={isDisabled}
            option={ssl || []}
            onChangeHandler={({ e, onChange }) => {
              onChange?.(e?.target?.value);
            }}
          />
        </div>
        <div className="col-span-2">
          <CommonTextInput
            label="VESSEL"
            inputName="vessel"
            maxLength={30}
            disabled={isDisabled}
            onChangeHandler={({ e, onChange }) => {
              onChange?.(e?.target?.value);
            }}
          />
        </div>
        <div className="col-span-2">
          <CommonTextInput
            label="COMMODITY"
            inputName="commodity"
            disabled={isDisabled}
            onChangeHandler={({ e, onChange }) => {
              onChange?.(e?.target?.value);
            }}
          />
        </div>
        <div className="col-span-2">
          <CommonDate
            label="ERD"
            inputName="erd"
            disabled={isDisabled}
            onChangeHandler={({ e, onChange, ...handlerProps }) => {
              if (e && dayjs(e).isValid())
                onChange?.(dayjs(e).format('YYYY-MM-DD'));
              else onChange?.(null);

              if (e?.format('YYYY-MM-DD') !== erdDefaultValue)
                setLogERDOpen(true);
              else setLogERDOpen(false);
            }}
          />
        </div>
        <div className="col-span-1">
          <CommonDate
            label="C/O"
            inputName="cutoff_date"
            disabled={isDisabled}
            onChangeHandler={({ e, onChange, ...handlerProps }) => {
              if (e && dayjs(e).isValid())
                onChange?.(dayjs(e).format('YYYY-MM-DD'));
              else onChange?.(null);

              if (e?.format('YYYY-MM-DD') !== coDefaultValues[0])
                setLogCOOpen(prev => ({ ...prev, date: true }));
              else setLogCOOpen(prev => ({ ...prev, date: false }));
            }}
            required={!!useWatch({ name: 'cutoff_time' })}
          />
        </div>
        <div className="col-span-1">
          <CommonTime
            inputName="cutoff_time"
            disabled={isDisabled}
            onChangeHandler={({ e, onChange, ...handlerProps }) => {
              if (e && dayjs(e).isValid()) onChange?.(dayjs(e));
              else onChange?.(null);

              if (e?.format('HH:mm:ss') !== coDefaultValues[1])
                setLogCOOpen(prev => ({ ...prev, time: true }));
              else setLogCOOpen(prev => ({ ...prev, time: false }));
            }}
            required={!!useWatch({ name: 'cutoff_date' })}
          />
        </div>
        <div className="col-span-2">
          <CommonTextSelect
            label="FREEDAYS"
            inputName={['freedays', 'freedays_type']}
            required={[true, true]}
            disabled={[isDisabled, isDisabled]}
            option={COMMON_FREEDAYS_SELECT_OPTION}
            regex={REGEX_NUMBER_ONLY_POSITIVE_DECIMAL}
            onChangeHandler={[
              ({ e, onChange, regex }) => {
                if (e?.target?.value?.match(regex)) {
                  onChange?.(e?.target?.value);
                }
              },
              ({ e, onChange }) => {
                onChange?.(e?.target?.value);
              },
            ]}
          />
        </div>
        <div className="col-span-2">
          <CommonAutoComplete
            label="PULL OUT LOC"
            inputName="po_location"
            disabled={isDisabled}
            maxLength={8}
            option={loc || []}
            onChangeHandler={({ e, selectProps, onChange }) => {
              onChange?.(selectProps?.value);
            }}
            isLoading={isLoadingBillToLoc}
          />
        </div>
        <div className="col-span-2">
          <CommonAutoComplete
            label="DELIVERY LOC"
            inputName="dilv_location"
            required
            disabled={isDisabled}
            maxLength={8}
            option={loc || []}
            onChangeHandler={({ e, selectProps, onChange }) => {
              onChange?.(selectProps?.value);
            }}
            isLoading={isLoadingBillToLoc}
          />
        </div>
        <div className="col-span-2">
          <CommonAutoComplete
            label="RETURN LOC"
            inputName="rtn_location"
            required
            disabled={isDisabled}
            maxLength={8}
            option={loc || []}
            onChangeHandler={({ e, selectProps, onChange }) => {
              onChange?.(selectProps?.value);
            }}
            isLoading={isLoadingBillToLoc}
          />
        </div>
      </div>
      {isEditMode && logERDOpen && (
        <HistoryLogSection title="ERD" field="erd" />
      )}
      {isEditMode &&
        !Object.values(logCOOpen).every(value => value === false) && (
          <HistoryLogSection title="C/O" field="cutoff" />
        )}
      <div className="flex pt-[20px]">
        <DispatchBookingTable
          colHeaderColor
          rowHeaderColor
          width="w-[910px]"
          data={[
            [
              { data: 'CONTAINER', header: true },
              { data: 'SIZE', header: true },
              { data: 'TYPE', header: true },
              { data: 'TOTAL', header: true },
              { data: 'USED', header: true },
              { data: 'BALANCE', header: true },
            ],
            [
              { data: `1ST CONT`, header: true, required: true },
              {
                data: '',
                InputName: 'cont_size_1',
                component: 'select',
                required: true,
                disabled: isDisabled,
                option: size || [],
                onChangeHandler: ({
                  e,
                  onChange,
                  selectProps,
                  ...handlerProps
                }) => {
                  onChange(e?.target?.value);
                },
              },
              {
                data: '',
                InputName: 'cont_type_1',
                component: 'select',
                required: true,
                disabled: isDisabled,
                option: type || [],
                onChangeHandler: ({
                  e,
                  onChange,
                  selectProps,
                  ...handlerProps
                }) => {
                  onChange(e?.target?.value);
                },
              },
              {
                data: 0,
                InputName: 'total_1',
                component: 'text',
                required: true,
                disabled: isDisabled,
                handleBalance: () => {
                  setValue(
                    'balance_1',
                    getValues('total_1') - (getValues('used_1') || 0),
                  );
                },
              },
              {
                data: 0,
                InputName: 'used_1',
                component: 'text',
                required: true,
                disabled: IS_DISABLED_COUNT,
              },
              {
                data: 0,
                InputName: 'balance_1',
                disabled: IS_DISABLED_COUNT,
                component: 'text',
                required: true,
              },
            ],
            [
              { data: `2ND CONT`, header: true },
              {
                data: '',
                InputName: 'cont_size_2',
                component: 'select',
                disabled: isDisabled,
                option: size || [],
                onChangeHandler: ({
                  e,
                  onChange,
                  selectProps,
                  ...handlerProps
                }) => {
                  onChange(e?.target?.value);
                },
              },
              {
                data: '',
                InputName: 'cont_type_2',
                component: 'select',
                disabled: isDisabled,
                option: type || [],
                onChangeHandler: ({
                  e,
                  onChange,
                  selectProps,
                  ...handlerProps
                }) => {
                  onChange(e?.target?.value);
                },
              },
              {
                data: 0,
                InputName: 'total_2',
                component: 'text',
                disabled: isDisabled,
                handleBalance: () => {
                  setValue(
                    'balance_2',
                    getValues('total_2') - (getValues('used_2') || 0),
                  );
                },
              },
              {
                data: 0,
                InputName: 'used_2',
                component: 'text',
                disabled: IS_DISABLED_COUNT,
              },
              {
                data: 0,
                InputName: 'balance_2',
                component: 'text',
                disabled: IS_DISABLED_COUNT,
              },
            ],
            [
              { data: `3RD CONT`, header: true },
              {
                data: '',
                InputName: 'cont_size_3',
                component: 'select',
                disabled: isDisabled,
                option: size || [],
                onChangeHandler: ({
                  e,
                  onChange,
                  selectProps,
                  ...handlerProps
                }) => {
                  onChange(e?.target?.value);
                },
              },
              {
                data: '',
                InputName: 'cont_type_3',
                component: 'select',
                disabled: isDisabled,
                option: type || [],
                onChangeHandler: ({
                  e,
                  onChange,
                  selectProps,
                  ...handlerProps
                }) => {
                  onChange(e?.target?.value);
                },
              },
              {
                data: 0,
                InputName: 'total_3',
                component: 'text',
                disabled: isDisabled,
                handleBalance: () => {
                  setValue(
                    'balance_3',
                    getValues('total_3') - (getValues('used_3') || 0),
                  );
                },
              },
              {
                data: 0,
                InputName: 'used_3',
                component: 'text',
                disabled: IS_DISABLED_COUNT,
              },
              {
                data: 0,
                InputName: 'balance_3',
                component: 'text',
                disabled: IS_DISABLED_COUNT,
              },
            ],
          ]}
        />
      </div>
    </div>
  );
};

export default BookingDetailContent;
