import React, { useEffect, useState } from 'react';
import HISTORY_TABLE_HEADER from '../../../../../constants/Dispatch/Export/Common/historyTableHeader';
import createBookingTableCell from '../../../../../utils/Dispatch/Export/Booking/createBookingTableCell';
import DispatchBookingTable from '../../Common/DispatchBookingTable';

const HistoryContent = ({
  bookingNo,
  bookingSeq,
  evidenceMutator,
  bookingHistory,
  ...props
}) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (!bookingHistory) return;
    setTableData([
      HISTORY_TABLE_HEADER,

      ...bookingHistory.map((row, index) => {
        return HISTORY_TABLE_HEADER.map(cell => {
          return createBookingTableCell({
            row,
            index,
            cell,
            bookingNo,
            bookingSeq,
            evidenceMutator,
          });
        });
      }),
    ]);
  }, [bookingHistory]);

  return (
    <div className="h-[463px] w-fit overflow-y-auto">
      <DispatchBookingTable width="w-[910px]" height="h-fit" data={tableData} />
    </div>
  );
};

export default HistoryContent;
