import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { REGEX_NUMBER_REGEX } from '../../../../../constants/Common/regex';
import { COMMON_STATE_SELECT_OPTION } from '../../../../../constants/Common/selectOption';
import {
  MASTER_COMPANY_CUSTOMER_TYPE,
  MASTER_COMPANY_VENDOR_TYPE,
} from '../../../../../constants/Master/Company/masterCompanyOption';
import { splitPhoneNumber } from '../../../../../utils/Common/Handler/phoneNumberHandler';
import generateByDate from '../../../../../utils/Master/Common/Generator/generateByDate';
import {
  handleAutoCompleteChange,
  handleCheckChange,
  handleInputChange,
  handleUpperCaseInputChange,
} from '../../../../../utils/Master/Common/Handler/changeInputHandler';
import handlePlaceSelect from '../../../../../utils/Master/Common/Handler/handlePlaceSelect';
import resetPlace from '../../../../../utils/Master/Common/resetPlace';
import CommonAutoComplete from '../../../../Inputs/CommonAutoComplete/CommonAutoComplete';
import CommonPhoneNumber from '../../../../Inputs/CommonPhoneNumber';
import CommonRadioInput from '../../../../Inputs/CommonRadioInput/CommonRadioInput';
import CommonSelect from '../../../../Inputs/CommonSelect/CommonSelect';
import CommonTextInput from '../../../../Inputs/CommonTextInput/CommonTextInput';
import CommonToggle from '../../../../Inputs/CommonToggle/CommonToggle';
import AutoCompleteAddress from '../../../Common/AutoCompleteAddress';
import validateEmail from '../../../../../utils/Common/Input/Validator/validateEmail';

const CompanyBasic = ({
  categoryOption,
  companyData,
  type,
  setType,
  isEditable,
  companyIDOption,
  ...props
}) => {
  const { setValue } = useFormContext();

  const [customerTypeOption, setCustomerTypeOption] = useState('');
  const [isCompGroup, setIsCompGroup] = useState(false);
  const [place, setPlace] = useState(companyData?.address_01 || '');
  const [typeLabel, setTypeLabel] = useState('');

  useEffect(() => {
    if (type === 'category-Customer' || type === 'Customer') {
      setTypeLabel('CUSTOMER TYPE');
      setCustomerTypeOption(MASTER_COMPANY_CUSTOMER_TYPE);
      setIsCompGroup(true);
    } else if (type === 'category-Vendor' || type === 'Vendor') {
      setTypeLabel('VENDOR TYPE');
      setCustomerTypeOption(MASTER_COMPANY_VENDOR_TYPE);
      setIsCompGroup(true);
    } else setIsCompGroup(false);
  }, [type]);

  return (
    <div>
      <div className="flex items-center gap-x-[20px]">
        <div className="w-[302px]">
          <CommonRadioInput
            inputName="category"
            label="COMPANY TYPE"
            defaultValue={
              props?.editMode
                ? companyData?.category
                : categoryOption?.editOption?.[0]?.value
            }
            disabled={props?.editMode && [true, true, true, true]}
            width="w-full"
            required
            borderVisible
            onChangeHandler={({ e, onChange, ...handlerProps }) => {
              onChange?.(e?.target?.value);
              setType(e?.target?.id);
            }}
            options={
              props?.editMode
                ? categoryOption?.viewOption
                : categoryOption?.editOption
            }
          />
        </div>
        {isCompGroup && (
          <div className="w-[173px]">
            <CommonSelect
              inputName="type"
              label={typeLabel}
              required
              option={customerTypeOption}
              onChangeHandler={handleInputChange}
              defaultValue={companyData?.type}
              disabled={!isEditable}
            />
          </div>
        )}
      </div>
      <div className="flex items-center justify-between">
        <div className="w-[141px]">
          <CommonTextInput
            inputName="company"
            label="COMPANY ID"
            defaultValue={companyData?.company || ''}
            onChangeHandler={handleInputChange}
            disabled
          />
        </div>
        <div className="w-[141px]">
          <CommonTextInput
            inputName="created_by"
            label="CREATED BY"
            defaultValue={generateByDate(companyData, [
              'created_by',
              'created_date',
            ])}
            disabled
          />
        </div>
        <div className="w-[141px]">
          <CommonTextInput
            inputName="updated_by"
            label="UPDATED BY"
            defaultValue={generateByDate(companyData, [
              'updated_by',
              'updated_date',
            ])}
            onChangeHandler={handleInputChange}
            disabled
          />
        </div>
        <div className="w-[141px]">
          <CommonTextInput
            inputName="qb_id"
            label="QB ID"
            defaultValue={companyData?.qb_id || ''}
            onChangeHandler={handleInputChange}
            disabled
          />
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div className="w-[195px]">
          <CommonTextInput
            inputName="business_name"
            label="BUSINESS NAME (DBA)"
            defaultValue={companyData?.business_name?.toUpperCase() || ''}
            onChangeHandler={handleInputChange}
            onBlurHandler={handleUpperCaseInputChange}
            required
            maxLength={100}
            disabled={!isEditable}
          />
        </div>
        <div className="w-[195px]">
          <CommonTextInput
            inputName="legal_name"
            label="LEGAL NAME"
            defaultValue={companyData?.legal_name || ''}
            onChangeHandler={handleInputChange}
            maxLength={100}
            disabled={!isEditable}
          />
        </div>
        <div className="w-[32px] mr-[10px]">
          <CommonToggle
            label="ACTIVE"
            options={[
              {
                label: 'Active',
                defaultValue: companyData ? companyData?.active : true,
                inputName: 'active',
              },
            ]}
            onChangeHandler={handleCheckChange}
            defaultValue={companyData?.active || ''}
            disabled={!isEditable}
          />
        </div>
        <div className="w-[141px]">
          <CommonAutoComplete
            inputName="group_id"
            label="GROUP ID"
            defaultValue={companyData?.group_id || ''}
            option={companyIDOption}
            onChangeHandler={handleAutoCompleteChange}
            disabled={!isEditable}
          />
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div className="w-[302px]">
          <AutoCompleteAddress
            inputName="address_01"
            label="ADDRESS 01"
            place={place}
            setPlace={setPlace}
            defaultValue={companyData?.address_01 || ''}
            onPlaceSelect={({ ...placeData }) => {
              handlePlaceSelect({
                setValue,
                ...placeData,
              });
            }}
            required
            onChangeHandler={({ e, onChange, ...handlerProps }) => {
              resetPlace({ e, setPlace, setValue });
              onChange?.(e?.target?.value);
            }}
            disabled={!isEditable}
          />
        </div>
        <div className="w-[302px]">
          <CommonTextInput
            inputName="address_02"
            maxLength={255}
            label="ADDRESS 02"
            onChangeHandler={handleInputChange}
            defaultValue={companyData?.address_02 || ''}
            disabled={!isEditable}
          />
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div className="w-[109px]">
          <CommonTextInput
            inputName="city"
            label="CITY"
            onChangeHandler={handleInputChange}
            defaultValue={companyData?.city || ''}
            disabled={!isEditable || !isCompGroup}
          />
        </div>
        <div className="w-[109px]">
          <CommonSelect
            inputName="state"
            label="STATE"
            onChangeHandler={handleInputChange}
            defaultValue={companyData?.state || ''}
            disabled={!isEditable || !isCompGroup}
            required={!isCompGroup}
            option={COMMON_STATE_SELECT_OPTION}
          />
        </div>
        <div className="w-[109px]">
          <CommonTextInput
            maxLength={20}
            inputName="zip"
            label="ZIP"
            onChangeHandler={handleInputChange}
            defaultValue={companyData?.zip || ''}
            disabled={!isEditable || !isCompGroup}
            required={!isCompGroup}
          />
        </div>
        <div className="w-[109px]">
          <CommonTextInput
            inputName="country"
            label="COUNTRY"
            defaultValue={companyData?.country || ''}
            onChangeHandler={handleInputChange}
            disabled
            required
          />
        </div>
        <div className="w-[109px]">
          <CommonTextInput
            inputName="timezone"
            label="TIME ZONE"
            defaultValue={companyData?.timezone || ''}
            onChangeHandler={handleInputChange}
            disabled
          />
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div className="w-[367px]">
          <CommonTextInput
            inputName="contact_name"
            label="CONTACT NAME"
            defaultValue={companyData?.contact_name || ''}
            onChangeHandler={handleInputChange}
            maxLength={30}
            disabled={!isEditable}
          />
        </div>
        <div className="w-[237px]">
          <CommonTextInput
            inputName="email"
            label="EMAIL"
            defaultValue={
              companyData?.email && companyData?.email?.length > 0
                ? companyData?.email?.join(', ')
                : ''
            }
            onChangeHandler={handleInputChange}
            maxLength={100}
            disabled={!isEditable}
          />
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div className="w-[242px]">
          <CommonPhoneNumber
            inputName={['cell_no_country', 'cell_no']}
            label="CELL #"
            defaultValue={() =>
              splitPhoneNumber({ phone: companyData?.cell_no })
            }
            disabled={!isEditable}
          />
        </div>
        <div className="w-[242px]">
          <CommonPhoneNumber
            inputName={['office_no_country', 'office_no']}
            label="OFFICE #"
            defaultValue={() =>
              splitPhoneNumber({ phone: companyData?.office_no })
            }
            disabled={!isEditable}
          />
        </div>
        <div className="w-[109px]">
          <CommonTextInput
            inputName="ext_no"
            label="EXT #"
            defaultValue={companyData?.ext_no || ''}
            maxLength={5}
            onChangeHandler={handleInputChange}
            regex={REGEX_NUMBER_REGEX}
            disabled={!isEditable}
          />
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div className="w-[242px]">
          <CommonPhoneNumber
            inputName={['fax_country', 'fax']}
            label="FAX"
            defaultValue={() => splitPhoneNumber({ phone: companyData?.fax })}
            disabled={!isEditable}
          />
        </div>
        <div className="w-[366px]">
          <CommonTextInput
            inputName="remarks"
            label="REMARKS"
            defaultValue={companyData?.remarks || ''}
            onChangeHandler={handleInputChange}
            maxLength={512}
            disabled={!isEditable}
          />
        </div>
      </div>
    </div>
  );
};

export default CompanyBasic;
