import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Autocomplete, MenuItem, TextField } from '@mui/material';
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import StyledEditCellRenderer from '../../../../../styles/Common/CommonDatagrid/EditCellRenderer/StyledEditCellRenderer';
import autoCompleteFilterOptionsHandler from '../../../../../utils/Common/Input/autoCompleteFilterOptionsHandler';

/**
 * @description
 * Auto complete modification cell renderer
 * @param {object} props
 * Grid props
 * @param {object} ref
 * Grid reference
 */
const GridAutoCompleteCell = forwardRef((props, ref) => {
  /** Get grid parameter */
  const {
    setValue,
    onValueChange,
    adornment,
    option: autoCompleteOption,
    maxLength: autoCompleteMaxLength,
    onChangeHandler,
    onInputChangeHandler,
    isCancelBeforeStartHandler,
  } = props;

  /** Input inner component reference */
  const compRef = useRef(null);
  const compInputRef = useRef(null);

  /** Input component property */
  /** Have to check difference between value and inputValue */
  const [value, setDataValue] = useState(props?.value || '');
  const [inputValue, setInputValue] = useState(props?.value || '');
  const [option, setOption] = useState(autoCompleteOption || []);
  const [maxLength, setMaxLength] = useState(autoCompleteMaxLength || 20);
  const [disabled, setDisabled] = useState(props?.disabled || false);
  const [isLoading, setIsLoading] = useState(props?.isLoading || false);

  /** Component data setter */
  const compDataSetter = {
    setDataValue,
    setInputValue,
    setOption,
    setMaxLength,
    setDisabled,
    setIsLoading,
  };

  /**
   * Component Editor Lifecycle methods
   * @see https://www.ag-grid.com/react-data-grid/component-cell-editor/
   */
  useImperativeHandle(ref, () => {
    return {
      /** The final value to send to the grid, on completion of editing */
      getValue() {
        return inputValue;
      },
      isCancelBeforeStart() {
        isCancelBeforeStartHandler?.({ ...props, ...compDataSetter });
        return false;
      },
      isCancelAfterEnd() {
        return false;
      },
      ref,
      compRef,
      compInputRef,
      ...compDataSetter,
      ...props,
    };
  });

  /** Render component */
  return (
    <StyledEditCellRenderer>
      <Autocomplete
        ref={compRef}
        forcePopupIcon={false}
        autoHighlight
        value={value || ''}
        inputValue={inputValue || ''}
        onInputChange={(e, newInputValue) => {
          if (e) {
            setInputValue(newInputValue);
            onInputChangeHandler({ newInputValue, ...props });
          }
        }}
        onChange={(e, newValue) => {
          const selectedValue = newValue?.value;
          setValue?.(selectedValue);
          setDataValue?.(selectedValue);
          onValueChange?.(selectedValue);
          onChangeHandler?.({ getChangedValue: selectedValue, ...props });
        }}
        filterOptions={(options, params) =>
          autoCompleteFilterOptionsHandler({ options, params })
        }
        options={option || []}
        isOptionEqualToValue={(paramOption, paramValue) =>
          paramOption.value === paramValue.value ||
          paramOption?.value === paramValue
        }
        noOptionsText={isLoading ? 'Loading...' : 'No options'}
        disabled={disabled}
        readOnly={disabled}
        renderOption={(params, renderOption) => {
          return (
            <MenuItem
              value={renderOption?.value}
              key={renderOption?.value}
              className="text-xs"
              {...params}
            >
              {renderOption?.label}
            </MenuItem>
          );
        }}
        renderInput={params => {
          return (
            <TextField
              {...params}
              inputRef={compInputRef}
              disabled={disabled}
              sx={{
                borderStyle: 'solid',
                borderColor: '#D9D9D9',
                borderRadius: '5px',
                backgroundColor: disabled ? '#F9F9F9' : 'white',
              }}
              inputProps={{
                ...params.inputProps,
                maxLength,
              }}
            />
          );
        }}
        popupIcon={<ExpandMoreIcon />}
        /** Component Style */
        className={`flex justify-center items-center h-[24px] ${
          disabled ? `bg-[#F9F9F9]` : `bg-white`
        }`}
        sx={{
          '&.MuiAutocomplete-root': {
            width: '100% !important',
            borderRadius: '5px',
          },
          '.MuiInputBase-root': {
            lineHeight: 'normal !important',
            padding: '0 40px 0 0 !important',
            borderRadius: '5px',
            fontWeight: '500',
            '&:not(.Mui-focused):not(.Mui-disabled):hover fieldset': {
              borderColor: '#8E9396 !important',
            },
            '&.Mui-focused fieldset': {
              border: '1.2px solid #264B9F !important',
              boxShadow: '0px 0px 4px 0px #8BBCE9 !important',
            },
            '&.Mui-error fieldset': {
              border: '1.2px solid #C24D4D !important',
            },
            '&.Mui-disabled fieldset': {
              backgroundColor: 'unset !important',
            },
            '.MuiInputBase-input.MuiOutlinedInput-input': {
              border: 'none !important',
            },
          },
          '.MuiInputBase-root > .MuiInputBase-input': {
            padding: '0 0 0 2px !important',
            height: '100%',
            fontSize: '12px',
          },
          '.MuiSvgIcon-root': {
            fontSize: '16px',
            color: '#264B9F !important',
          },
          '&.MuiAutocomplete-popper': {
            zIndex: 9999,
          },
          fieldset: {
            borderColor: '#D9D9D9 !important',
          },
        }}
        componentsProps={{
          popper: {
            style: { width: 'fit-content' },
            placement: 'bottom-start',
            sx: {
              zIndex: 9999,
              '.MuiAutocomplete-option': {
                fontSize: '12px !important',
              },
              '.MuiAutocomplete-noOptions': {
                fontSize: '12px !important',
              },
            },
          },
        }}
      />
    </StyledEditCellRenderer>
  );
});

export default GridAutoCompleteCell;
