import React, { useCallback, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import getFilterButtonData from '../../../utils/Dispatch/Common/Generator/getFilterButtonData';
import MainFilterButton from './MainFilterButton';
import handleSorting from '../../../utils/Dispatch/Common/Handler/handleSorting';
import { DISPATCH_TYPE_EXPORT } from '../../../constants/Dispatch/Common/category';

const FilterButtons = ({
  gridRef,
  status,
  category,
  mainFilter,
  subFilter,
  setSubFilter,
  allQueries,
}) => {
  const settings = getFilterButtonData({ category });

  const statusHeaders = useMemo(() => {
    return settings?.children
      ?.filter(main => main?.status === status)?.[0]
      ?.children?.map(sub =>
        sub?.header?.toLowerCase()?.replace(' #', '')?.replace(' ', '-'),
      );
  }, [status]);

  if (mainFilter === settings?.to) {
    mainFilter =
      category === DISPATCH_TYPE_EXPORT
        ? settings?.children?.[1]?.to
        : settings?.children?.[0]?.to;
  }

  /* Grid Sorting */
  useEffect(() => {
    if (statusHeaders?.includes(subFilter)) {
      handleSorting({ gridRef, status, subFilter });
    }
  }, [subFilter, statusHeaders]);

  return (
    <div className="w-[1780px] h-fit flex flex-row mt-[20px] gap-[10px] text-[12px] font-[700] text-[#B2BDC9]">
      {settings?.children?.map(main => {
        return (
          <Link
            to={`${main?.to}`}
            onClick={() => {
              setSubFilter(main?.children?.[0]?.header?.toLowerCase());
            }}
            key={main?.header}
          >
            <MainFilterButton
              allQueries={allQueries}
              settings={settings}
              main={main}
              mainFilter={mainFilter}
              subFilter={subFilter}
              onClick={setSubFilter}
            />
          </Link>
        );
      })}
    </div>
  );
};

export default FilterButtons;
