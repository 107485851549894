const INIT_BOOKING_ADD = {
  bk_no: '',
  billto: '',
  doc: '',
  doc_file_name: '',
  created_by: '',
  updated_by: '',
  created_date: '',
  updated_date: '',
  ssl: '',
  vessel: '',
  commodity: '',
  erd: '',
  cutoff_date: '',
  cutoff_time: '',
  freedays: '',
  freedays_type: 'FD0001',
  po_location: '',
  dilv_location: '',
  rtn_location: '',
  cont_type_1: '',
  cont_size_1: '',
  total_1: '',
  used_1: 0,
  balance_1: 0,
  cont_type_2: '',
  cont_size_2: '',
  total_2: '',
  cont_type_3: '',
  cont_size_3: '',
  total_3: '',
};

const INIT_BOOKING_ADVANCED_SEARCH = {
  booking: '',
  status: 'active',
  date: '',
  dateFrom: '',
  dateTo: '',
};

const INIT_EXPORT_ADVANCED_SEARCH = {
  date: '',
  dateFrom: '',
  dateTo: '',
  category: '',
  categoryText: '',
};

export {
  INIT_BOOKING_ADD,
  INIT_BOOKING_ADVANCED_SEARCH,
  INIT_EXPORT_ADVANCED_SEARCH,
};
