import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  DISPATCH_BOOKING_STATUS_ACTIVE,
  DISPATCH_BOOKING_STATUS_INACTIVE,
} from '../../../../constants/Dispatch/Export/Common/bookingModalStatus';
import {
  DISPATCH_BOOKING_TAB_BK_DETAIL,
  DISPATCH_BOOKING_TAB_HISTORY,
  DISPATCH_BOOKING_TAB_WO,
} from '../../../../constants/Dispatch/Export/Common/bookingModalTab';
import { INIT_BOOKING_ADD } from '../../../../constants/Dispatch/Export/Common/formInit';
import usePatchCompleteBooking from '../../../../hooks/Dispatch/Export/Booking/usePatchCompleteBooking';
import usePatchDeleteBooking from '../../../../hooks/Dispatch/Export/Booking/usePatchDeleteBooking';
import usePatchEvidence from '../../../../hooks/Dispatch/Export/Booking/usePatchEvidence';
import usePatchUpdateBooking from '../../../../hooks/Dispatch/Export/Booking/usePatchUpdateBooking';
import CommonAlertModal from '../../../Common/CommonAlertModal';
import CommonBlur from '../../../CommonBlur/CommonBlur';
import CommonTabModal from '../../../CommonTabModal/CommonTabModal';
import BookingModalButtonContent from '../Common/BookingModalButtonContent';
import HistoryContent from './HistoryContent';
import UpdateBookingDetailContent from './UpdateBookingDetailContent';
import WorkOrderNumberContent from './WorkOrderNumberContent';

const UpdateBookingModal = ({
  isUpdateBookingModalOpen,
  setIsUpdateBookingModalOpen,
  tabIndexOpen,
  setTabIndexOpen,
  editWOData,
  bookingHistory,
  setBookingSeq,
  bookingSeq,
  queryObject,
  editData,
  setIsMessageModalOpen,
  setMessageModalMessage,
  ...props
}) => {
  const updateBookingController = useForm({
    shouldUnregister: true,
    defaultValues: { ...INIT_BOOKING_ADD },
  });

  const [deletedConfirmed, setDeletedConfirmed] = useState(false);
  const [alertModalOpen, setAlertModalOpen] = useState(false);

  useEffect(() => {
    updateBookingController.reset({
      ...editData,
      doc_file_name: editData?.doc?.split('/')?.pop() || '',
      cutoff_date: editData.cutoff,
      cutoff_time: editData.cutoff,
    });
  }, [editData, tabIndexOpen, isUpdateBookingModalOpen]);

  const saveButtonRef = useRef(null);

  const patchProps = {
    setIsMessageModalOpen,
    setMessageModalMessage,
    updateBookingController,
    setIsUpdateBookingModalOpen,
    setTabIndexOpen,
    setBookingSeq,
    bookingSeq,
  };

  const { mutate: bkCompleteMutator } = usePatchCompleteBooking({
    ...patchProps,
  });

  const { mutate: bkDeleteMutator } = usePatchDeleteBooking({
    ...patchProps,
  });

  const { mutate: bkDetailMutator } = usePatchUpdateBooking({
    ...patchProps,
  });

  const { mutate: evidenceMutator } = usePatchEvidence({
    ...patchProps,
  });

  const handleDeleteBooking = () => {
    setAlertModalOpen(true);
  };

  const confirmDeleteBooking = () => {
    setDeletedConfirmed(true);
    setAlertModalOpen(false);
    bkDeleteMutator({ bookingSeq });
  };

  const buttonCommonProps = {
    existWO: editWOData
      ?.map(item => item?.status !== 'COMPLETED')
      ?.includes(true),
    status:
      queryObject?.status === 'inactive'
        ? DISPATCH_BOOKING_STATUS_INACTIVE
        : DISPATCH_BOOKING_STATUS_ACTIVE,
    bkCompleteMutator,
    handleDeleteBooking,
    setIsMessageModalOpen,
    setMessageModalMessage,
    setModalOpen: setIsUpdateBookingModalOpen,
    setTabIndexOpen,
    setBookingSeq,
    bookingSeq,
    saveButtonRef,
  };

  return (
    <>
      <CommonBlur open={isUpdateBookingModalOpen}>
        <CommonTabModal
          modalOpen={isUpdateBookingModalOpen}
          setModalOpen={setIsUpdateBookingModalOpen}
          tabIndexOpen={tabIndexOpen}
          setTabIndexOpen={setTabIndexOpen}
          contents={[
            {
              headerName: 'Booking Detail',
              content: (
                <UpdateBookingDetailContent
                  editData={editData}
                  bookingSeq={bookingSeq}
                  queryObject={queryObject}
                  bkDetailMutator={bkDetailMutator}
                  setTabIndexOpen={setTabIndexOpen}
                  updateBookingController={updateBookingController}
                  saveButtonRef={saveButtonRef}
                  {...props}
                />
              ),
              buttonContent: (
                <BookingModalButtonContent
                  tab={DISPATCH_BOOKING_TAB_BK_DETAIL}
                  {...buttonCommonProps}
                  saveFormID="bookingDetailForm"
                />
              ),
            },
            {
              headerName: 'History',
              content: (
                <HistoryContent
                  bookingNo={editData.bk_no}
                  bookingSeq={bookingSeq}
                  bookingHistory={bookingHistory}
                  evidenceMutator={evidenceMutator}
                />
              ),
              buttonContent: (
                <BookingModalButtonContent
                  tab={DISPATCH_BOOKING_TAB_HISTORY}
                  {...buttonCommonProps}
                />
              ),
            },
            {
              headerName: 'WO #',
              content: <WorkOrderNumberContent data={editWOData} />,
              buttonContent: (
                <BookingModalButtonContent
                  tab={DISPATCH_BOOKING_TAB_WO}
                  {...buttonCommonProps}
                  saveFormID="bookingDetailForm"
                />
              ),
            },
          ]}
        />
      </CommonBlur>
      <CommonAlertModal
        open={alertModalOpen}
        setOpen={setAlertModalOpen}
        message={`You're trying to delete BK# “${editData.bk_no}”. Are you sure?`}
        onOkClick={confirmDeleteBooking}
        onCloseClick={() => setAlertModalOpen(false)}
      />
    </>
  );
};

export default UpdateBookingModal;
